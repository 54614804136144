import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import VenueHeader from "./../components/VenueHeader";
import { BigButton } from "./../components/Buttons";
import { H3Strong, H2SmallStrong, H4Big } from "./../components/Typography";
import RegisterYourInterestForm from "./../components/RegisterYourInterestForm";
import {
  VenueBottomDiv,
  SubtitleWrapper,
} from "./../components/VenueStyles/elements";

const EventManagement = () => {
  const eventsQuery = useStaticQuery(graphql`
    {
      craftEventManagementEventManagementEntry {
        title
        richTextField
        callToAction
      }
    }
  `).craftEventManagementEventManagementEntry;
  const { title, richTextField, callToAction } = eventsQuery;
  const [formShown, setFormShown] = React.useState(false);

  return (
    <Layout
      backgroundColor={"var(--green)"}
      footerColor={"var(--green)"}
      headerColor={"var(--black)"}
      headerBlack
      title={title}
      SEODescription={richTextField}
    >
      <VenueHeader title={title} titleIsBig />
      <SubtitleWrapper>
        <H3Strong dangerouslySetInnerHTML={{ __html: richTextField }} />
      </SubtitleWrapper>
      <VenueBottomDiv>
        <H2SmallStrong>Planning an event?</H2SmallStrong>
        <H4Big>See how we can help you</H4Big>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <BigButton
            color={"var(--black)"}
            onClick={() => {
              setFormShown(true);
            }}
          >
            {callToAction || "Get in touch"}
          </BigButton>
        </a>
      </VenueBottomDiv>
      {formShown ? (
        <RegisterYourInterestForm
          closeForm={() => {
            setFormShown(false);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default EventManagement;

import React from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import Img from "gatsby-image";
import VenueScrollSelector from "./../VenueScrollSelector";
import { VenueHeaderDiv } from "./elements";

const VenueHeader = ({ title, selected, titleIsBig }) => {
  // console.log(selected);
  const venueQuery = useStaticQuery(graphql`
    {
      images: craftEventManagementEventManagementEntry {
        venuesHeaderImages {
          ... on Craft_venuesHeaderImages_headerImages_BlockType {
            id
            a4SpaceImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  childImageSharp {
                    fixed(height: 420, width: 540, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            concreteImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  childImageSharp {
                    fixed(height: 420, width: 540, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            shellCoreImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  childImageSharp {
                    fixed(height: 420, width: 540, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            theYardImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  childImageSharp {
                    fixed(height: 420, width: 540, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            jossaImage {
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  childImageSharp {
                    fixed(height: 420, width: 540, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      a4Slug: craftVenuesVenuesEntry(title: { eq: "A4 Space" }) {
        slug
      }
      theYardSlug: craftVenuesVenuesEntry(title: { eq: "The Yard" }) {
        slug
      }
      concreteSlug: craftVenuesVenuesEntry(title: { eq: "Concrete" }) {
        slug
      }
      shellCoreSlug: craftVenuesVenuesEntry(title: { eq: "Shell & Core" }) {
        slug
      }
      jossaSlug: craftVenuesVenuesEntry(title: { eq: "Jossa by Alserkal" }) {
        slug
      }
    }
  `);
  // console.log(venueQuery);
  const venueImages = venueQuery.images.venuesHeaderImages[0];
  const menuItems = [
    {
      title: "Concrete",
      image: (
        <Img fixed={venueImages.concreteImage[0].slide.childImageSharp.fixed} />
      ),
      link: `/venue/${venueQuery.concreteSlug.slug}`,
    },
    
    
    //  {
    //   title: "A4 Space",
    //   image: (
    //     <Img fixed={venueImages.a4SpaceImage[0].slide.childImageSharp.fixed} />
    //    ),
    //   link: `/venue/${venueQuery.a4Slug}`,
    //  },
    {
      title: "The Yard",
      image: (
        <Img fixed={venueImages.theYardImage[0].slide.childImageSharp.fixed} />
      ),
      link: `/venue/${venueQuery.theYardSlug.slug}`,
    },
    {
      title: "Shell & Core",
      image: (
        <Img
          fixed={venueImages.shellCoreImage[0].slide.childImageSharp.fixed}
        />
      ),
      link: `/venue/${venueQuery.shellCoreSlug.slug}`,
    },
    {
      title: "Jossa",
      image: (
        <Img fixed={venueImages.jossaImage[0].slide.childImageSharp.fixed} />
      ),
      link: `/venue/${venueQuery.jossaSlug?.slug || ""}`,
    },
  ];
  // console.log(menuItems);
  return (
    <VenueHeaderDiv>
      <div>
        <VenueScrollSelector
          title={title}
          menuItems={menuItems}
          selected={selected}
          titleIsBig={titleIsBig}
          returnLink={(e) => {
            // console.log(e);
            navigate(e);
          }}
        />
      </div>
    </VenueHeaderDiv>
  );
};

export default VenueHeader;

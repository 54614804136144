import styled from "styled-components";

export const ArticleBodyWrapper = styled.div`
  background-color: var(--white);
  & > div {
    margin-bottom: 0;
  }
  & .imageslider {
  //  background-color: var(--green);
    padding: 20px 0;
    margin-bottom: 0;
    &:before {
     // position: absolute;
      top: 0;
      bottom: 0;
      left: -100vw;
      right: -100vw;
      content: "";
     // background-color: var(--green);
     // z-index: 1;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 25px;
  }
`;

export const VenueBottomDiv = styled.div`
  &:before {
   // position: relative;
    left: -100vw;
    right: -100vw;
    width: 300vw;
   // background-color: var(--white);
    top: -50px;
    height: 50px;
    content: "";
   // z-index: 2;
  }
  width: 100%;
  background-color: var(--green);
  margin: 50px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  & h4 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px;
    & h2 {
      text-align: center;
    }
  }
`;

export const SubtitleWrapper = styled.div`
  background-color: var(--white);
  padding: 25px var(--outerMargin) 0 var(--outerMargin);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h3 {
    max-width: var(--width);
  }
  & p {
    margin: 0 0 var(--outerMargin) 0;
  }
  & button {
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    --outerMargin: 25px;
    & h3 {
      font-size: 18px;
    }
  }
`;

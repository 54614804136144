import React from "react";
import PropTypes from "prop-types";
import CoreScrollSelector from "./../CoreScrollSelector";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { VenueScrollSelectorDiv } from "./elements";

const titles = ["Concrete", "A4 Space", "The Yard", "Shell & Core", "Jossa"];

const VenueScrollSelector = ({
  title,
  menuItems,
  returnLink,
  selected,
  titleIsBig,
}) => {
  // console.log(selected);
  return (
    <VenueScrollSelectorDiv titleIsBig={titleIsBig}>
      <CoreScrollSelector
        menuItems={menuItems}
        returnLink={returnLink}
        venue
        selectedMode={selected ? true : false}
        selection={titles.indexOf(selected)}
        titleIsBig={titleIsBig}
      />
      <div>
        {/*<MiscSocialSmall color={"transparent"} />*/}
        <h1>{title}</h1>
      </div>
    </VenueScrollSelectorDiv>
  );
};
export default VenueScrollSelector;

VenueScrollSelector.propTypes = {
  title: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node,
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  returnLink: PropTypes.func,
};

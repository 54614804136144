import styled from "styled-components";

export const VenueScrollSelectorDiv = styled.div`
  width: var(--width);
  height: 652px;
  position: relative;
  & > div:first-of-type {
    position: absolute;
    top: 200px;
    width: 100%;
    height: 420px;
    & + div {
      z-index: 2;
      top: 35px;
      position: absolute;
      padding: 0 var(--outerMargin);
      pointer-events: none;
      & > svg {
        display: block;
      }
    }
  }
  & h1 {
    padding-top: 32px;
    color: var(--purple);
    font-family: var(--flexFont);
    font-size: ${(props) => (props.titleIsBig ? "90px" : "140px")};
    font-weight: 600;
    margin-top: ${(props) => (props.titleIsBig ? "31px" : "12px")};
    line-height: 140px;
    pointer-events: none;
    user-select: none;
  }
  @media (max-width: 1439px) {
    & > div + div h1 {
      ${(props) => props.titleIsBig && "font-size: 64px !important;"}
      ${(props) => props.titleIsBig && "margin-top: 50px !important;"}
    }
  }
  @media (max-width: 1023px) {
    width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
    & > div + div h1 {
      ${(props) => props.titleIsBig && "font-size: 56px !important;"}
      ${(props) => props.titleIsBig && "margin-top: 64px !important;"}
    }
  }
`;
